.IdeasBoard section {
  max-height: calc(100vh - 130px);
}

.IdeasBoard section > div a {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  /* height: 20px; */
  color: #6b808c;
  border-radius: 50%;
  margin: 0px 0 3px 8px;
  cursor: pointer;
  transition: opacity .5s ease-in-out;
}
.IdeasBoard section > div a:hover{
  text-decoration: underline;
  opacity: .6;
}

.IdeasBoard header > span {
  width: 100% !important;
}

.IdeasBoard .smooth-dnd-draggable-wrapper .react-trello-lane > div{
  margin-bottom: 0;
  padding-bottom: 0;
  margin: 0;
}

.IdeasBoard .smooth-dnd-container .comPlainTextContentEditable{
  padding: 6px;
  border: 1px solid #d8d5d5;
  border-radius: 2px;
}

.IdeasBoard .smooth-dnd-container:nth-child(2) button {
  font-weight: normal;
}
.IdeasBoard .smooth-dnd-container:nth-child(2) section {
  background-color: inherit;
}
.IdeasBoard .smooth-dnd-container:nth-child(2) button:nth-child(1) {
  background: #00b1f3;
  width: 100%;
}

.IdeasBoard .smooth-dnd-container section > div{
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.IdeasBoard .smooth-dnd-container section > div > div {
  order: 2;
}

.IdeasBoard .smooth-dnd-container section > div > span {
  order: 1;
}

.IdeasBoard .smooth-dnd-container section > div > span > div {
  margin-bottom: 10px;
}
