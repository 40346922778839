/* Override react-chatbot-kit styles */

/* Chat container */

.react-chatbot-kit-chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;
  height: 85vh;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  border-radius: 8px;
  position: relative;
}

.react-chatbot-kit-chat-inner-container {
  height: 95%;
  width: 100%;
  border-radius: 8px;
  background: rgb(245, 248, 251);
}

.react-chatbot-kit-chat-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-family: inherit;
  font-size: 0.9rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: medium;
}

.react-chatbot-kit-chat-message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 93%;
  width: 100%;
  margin-bottom: 1.5em;
  padding: 0 17.5px 10px 17.5px;
  overflow: scroll;
}

/* Bot Message */

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
  display: none;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  display: none;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}

.react-chatbot-kit-chat-bot-message {
  font-size: 0.9rem;
  font-weight: medium;
  position: relative;
  width: 70%;
  text-align: left;
  animation: 0.3s ease 0s 1 normal forwards running Lmuha;
  border-radius: 18px 18px 18px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  color: #514f4f;
  display: inline-block;
  margin: 0px 0px 10px;
  padding: 12px;
  transform-origin: left bottom;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2898ec;
  position: absolute;
  left: -7px;
  top: 13px;
}

/* User Message */

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.react-chatbot-kit-user-chat-message {
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  max-width: 70%;
  position: relative;
  text-align: left;
  background-color: rgb(105 178 209 / 10%);
  border-radius: 18px 18px 0px 18px;
  font-weight: medium;
  animation: 0.3s ease 0s 1 normal forwards running Lmuha;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
  color: #514f4f;
  display: inline-block;
  margin: 0px 0px 10px;
  padding: 12px;
  transform-origin: left bottom;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
}

.react-chatbot-kit-user-avatar-container {
  display: none;
}

.react-chatbot-kit-user-avatar-icon {
  display: none;
}

/* Input */

.react-chatbot-kit-chat-input-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  border-bottom-left-radius: 8px;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 8px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
  border-bottom-left-radius: 8px;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #2898ec;
  width: 100px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 8px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #fff;
  width: 15px;
  margin: 0 auto;
}

/* Loader */

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
    fill:#514f4f;
  }

  50% {
    opacity: 0.8;
    fill:#514f4f;
  }

  100% {
    opacity: 0;
    fill:#514f4f;
  }
}

/* Error */

.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}
