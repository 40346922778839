#merge-lightbox .container{
    margin-bottom:30px;
}

#merge-lightbox .container p{
    margin-bottom:10px;
}

#merge-lightbox .actions-container{
    text-align: right;
}

#merge-lightbox .actions-container button{
    margin-left:15px;
}
