.dragonbot-appear {
  opacity: 0.01;
}

.dragonbot-appear.dragonbot-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.rsc-os li.rsc-os-option {
  display: block;
}

.rsc-ts-bubble a {
  cursor: pointer;
}

.rsc-cs {
  background: none;
  border: none;
  box-shadow: none;
}

.rsc-cs:empty {
  display: none;
}

.rsc-os-options {
  display: flex;
  flex-wrap: wrap;
}
