.text-center {
  text-align: center;
}

body {
  background: #fff;
}

*[id='saved-views-dropdown'] {
  width: 280px;
}
iframe[id='launcher'] {
  opacity: 0;
  left: -100000px;
  top: -1000000px;
}

.Toastify__toast-container--top-right {
  top: 58px;
}

.Toastify__toast--default {
  box-shadow: 0px 4px 20px rgb(188 188 188 / 40%);
  border-radius: 4px;
  padding: 16px;
  font-size: 14px;
}

.Toastify__progress-bar--default {
  height: 1px;
}

.Toastify__toast-container {
  z-index: 1000000000;
}

.ag-dnd-ghost {
  visibility: hidden;
}