/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
.root,
[data-reactroot] {
  height: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

body {
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

em {
  font-weight: bold;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

a,
a:visited {
  color: #2ea8e1;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.material-ui-datepicker {
  font: inherit;
  color: rgba(0, 0, 0, 0.87);
  border: 0;
  margin: 0;
  padding: 10px 0 6px;
  display: block;
  min-width: 0;
  max-width: 100%;
  flex-grow: 1;
  box-sizing: content-box;
  background: none;
  vertical-align: middle;
  border-bottom: 1px solid;
  border-color: #e6e6e6;
  font-weight: 400;
  width: 100px;
}

.material-ui-datepicker:disabled {
  color: rgba(0, 0, 0, 0.38);
  border-bottom: 1px dotted;
}

.dragonboat-react-toastify-progress-bar {
  background: linear-gradient(45deg, #6ae2dc, #1291bd);
}
.Toastify__toast-body {
  line-height: 20px;
}

/* .scrollBar {
  scrollbar-width: none;
}

::-webkit-scrollbar { 
  width: 0px;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
  -webkit-border-radius: 6px;
} */

::-webkit-input-placeholder {
  color: rgba(0, 0, 0);
  opacity: 0.26;
}
