.fileUpload {
  overflow: hidden;
  z-index: 100;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
