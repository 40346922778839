.group-row-inner-renderer button {
  height: 47px;
  min-height: auto;
  min-width: auto;
  padding:0px 5px;
}

.group-row-inner-renderer button svg{
  font-size: 1.5rem;
}

.group-row-inner-renderer .label,
.ag-group-child-count{
  vertical-align:middle;
}
