div.remirror-editor-wrapper {
  padding-top: var(--rmr-space-2);
}

div.remirror-editor-wrapper em {
  font-style: italic;
  font-weight: inherit;
}

div.remirror-editor-wrapper strong {
  font-weight: bold;
}

div.remirror-editor-wrapper ul {
  list-style: disc;
  padding-left: 1em;

  & ul {
    list-style: circle;
    padding-left: 1em;
  }

  & ul ul {
    list-style: square;
    padding-left: 1em;
  }
}

div.remirror-editor-wrapper table ul {
  padding-left: 1.5em;
}

div.remirror-editor-wrapper table ol {
  padding-left: 1.5em;
}

div.remirror-editor-wrapper ol {
  list-style: decimal;
  padding-left: 1em;

  & ol {
    list-style: lower-alpha;
    padding-left: 1em;
  }

  & ol ol {
    list-style: lower-roman;
    padding-left: 1em;
  }
}

.remirror-editor.ProseMirror th {
  background-color: #f4f9fd;
}

.remirror-editor.ProseMirror td,
.remirror-editor.ProseMirror th {
  height: 50px;
}

button.remirror-button {
  border: 1px solid #009dff !important;
}

button.remirror-button:not([aria-disabled='true']):hover {
  background-color: #009dff !important;
}

.remirror-a11y-dark :not(pre) > code[class*='language-'],
.remirror-a11y-dark pre[class*='language-'] {
  background: #f9f9f9;
  color: #131c23;
}

.remirror-editor.ProseMirror hr {
  height: 1px;
  outline: none;
  border-color: #f9f9f9;
  padding: 2px 0px;
  margin: calc(1.714em - 4px) 0px;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.remirror-editor.ProseMirror a[rel] {
  color: #2ea8e1;
  cursor: pointer;
}

.remirror-theme .ProseMirror h1,
.remirror-theme .ProseMirror ol,
.remirror-theme .ProseMirror ul,
.remirror-theme .ProseMirror h2,
.remirror-theme .ProseMirror h3,
.remirror-theme .ProseMirror h4,
.remirror-theme .ProseMirror h4,
.remirror-theme .ProseMirror h5,
.remirror-theme .ProseMirror h6 {
  margin-bottom: 1rem;
}

.remirror-theme .ProseMirror code {
  font-family: monospace;
}

.remirror-theme.disabled .remirror-table-controller {
  display: none;
}

.remirror-editor.ProseMirror table {
  border-collapse: collapse;
  table-layout: unset;
  width: 100%;
  overflow: hidden;
}

.remirror-theme.disabled .remirror-table-colgroup > col:first-of-type {
  width: initial;
}

.remirror-theme table td > p {
  padding: 5px;
}

.remirror-theme .ProseMirror p,
.remirror-theme .ProseMirror span {
  margin-bottom: var(--rmr-space-2);
}

.remirror-theme sup {
  vertical-align: super;
  font-size: smaller;
}

.remirror-theme sub {
  vertical-align: sub;
  font-size: smaller;
}

.remirror-theme .ProseMirror {
  padding: var(--rmr-space-2);
  border: none;
  box-shadow: none;
  overflow-y: auto;
}

.remirror-theme .ProseMirror:active,
.remirror-theme .ProseMirror:focus {
  box-shadow: none;
}

.remirror-image-loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #2ea8e1;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
