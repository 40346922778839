.material--blue {
    color: #2196f3 !important;
}

.material--green {
    color: #2e7d32 !important;
}

.material--darkGray {
    color: #9e9e9e !important;
}

.material--darkerGray {
    color: #616161 !important;
}

.material--blue div p {
    color: #2196f3 !important;
}

.material--green div p {
    color: #2e7d32 !important;
}

.material--darkGray div p {
    color: #9e9e9e !important;
}

.material--darkerGray div p {
    color: #616161 !important;
}

.cell-link {
    color: #009dff !important;
    cursor: pointer;
}

.cell-link div p {
    color: #009dff !important;
    cursor: pointer;
}

.ag-cell {
    font-size: 12px;
    color: #b6b6b6;
}

.cell-link:hover {
    text-decoration: underline;
}

.cell-title:hover button,
.ag-cell:hover .ag-group-value button,
.ag-cell-value:hover button {
    visibility: visible !important;
}

.ag-row-drag {
    visibility: hidden;
}

.ag-row-hover .ag-row-drag {
    visibility: visible;
}

.ag-dnd-ghost-label {
    display: none;
}

.cell-click-to-open:hover {
    cursor: pointer;
    text-decoration: underline;
}