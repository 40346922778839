.custom-light-box-number-field {
  max-width: 100%;
}

.material-ui-label {
  padding: 0;
  font-size: 0.75rem;
  font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.54);
}
