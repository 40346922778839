.dhx_timeline_table_wrapper .dhx_marked_timespan {
  z-index: 100 !important;
  height: 100% !important;
  border-left-color: #934fea !important;
  opacity: 1;
}
.dhx_timeline_data_col > div:first-child .dhx_marked_timespan::after {
  position: absolute;
  content: 'Today';
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: #f8f9fc;
  background: #934fea 0% 0% no-repeat padding-box;
  border-radius: 0px 15px 15px 0px;
  padding: 3px 10px;
}

.dhx_matrix_scell.folder .dhx_scell_name {
  text-transform: none !important;
}
.dhx_cal_header {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}
.dhx_in_move:not(.planningStage--Backlog):not(.planningStage--Planning):not(.planningStage--Completed):not(.planningStage--Confirmed) {
  background: #c0e9f5 !important;
}
.dhx_timeline_table_wrapper .dhx_cal_event_line {
  border-radius: 15px !important;
  font-size: 12px !important;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 5px 8px;
}
.dhx_cal_event_line.disabled {
  cursor: initial;
}
.dhx_cal_event_line .dhx_event_resize {
  height: 10px !important;
  top: 8px !important;
}
.dhx_cal_event_line.planningStage--Backlog {
  background: #fff !important;
  border: 1px dashed !important;
  border-color: #333 !important;
  color: #333 !important;
}
.dhx_cal_event_line.planningStage--Planning {
  border: 1px dashed !important;
  border-color: #333 !important;
}
.dhx_cal_event_line.planningStage--Completed {
  background: #aaa9ad !important;
}
.dhx_cal_event_line.leftTimelineContinue {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.dhx_cal_event_line.leftTimelineContinue .dhx_event_resize_start {
  visibility: hidden;
}
.dhx_cal_event_line.rightTimelineContinue {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.dhx_cal_event_line.rightTimelineContinue .dhx_event_resize_end {
  visibility: hidden;
}

.dhx_scell_expand {
  color: transparent;
  width: 14px !important;
}
.opened .dhx_scell_expand:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCQwLJyTxUacQAAAAY0lEQVQoz2NgGAxAkmELgyBWGWGGrQyiTAwvGO4w7GMQxpAWYNjBcIPhNQMDAwMjwwSG82hKBBhOM/QiuOhK0KQhoAOuRIDhFKY0QglOaYhFZxnOMnTh9jIjQxtD44AENl4AALblFfONGxULAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTA5LTEyVDExOjM5OjM2KzAwOjAwwmYpEwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wOS0xMlQxMTozOTozNiswMDowMLM7ka8AAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC) !important;
  min-width: 20px;
  max-width: 20px;
  cursor: pointer;
  background-size: 10px !important;
  background-color: transparent !important;
  transform: none !important;
}
.closed .dhx_scell_expand:after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCQwLIwSuU0LcAAAANUlEQVQoz2NggAExBgJgK0MffgUCDKcZ+oeOEmGG8wwtMA4TId9jt6KPIul+WkkTEVk4ohsA7H4SPe/fzvAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDktMTJUMTE6MzU6MDQrMDA6MDDBSt9XAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTA5LTEyVDExOjM1OjA0KzAwOjAwsBdn6wAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) !important;
  min-width: 20px;
  max-width: 20px;
  cursor: pointer;
  background-size: 10px !important;
  background-color: transparent !important;
  transform: none !important;
}

.dhx_matrix_cell,
.dhx_matrix_scell {
  background-color: #efefef66 !important;
  border-right: none !important;
  border-bottom: 2px solid #fff !important;
}
.dhx_matrix_cell,
.dhx_matrix_scell .dhx_scell_name {
  font-size: 14px !important;
  color: #131c23;
  font-weight: 400 !important;
}
.dhx_data_table.folder .dhx_matrix_cell,
.dhx_matrix_scell.folder {
  background-color: #efefef !important;
}
.dhx_matrix_scell.folder .dhx_scell_name {
  color: #37a5da !important;
  font-size: 14px !important;
}
.dhx_matrix_scell.folder .dhx_scell_level1 .dhx_scell_name {
  color: #58ccc6 !important;
}

.dhx_cal_event_line .dhx_event_resize_start {
  left: 12px;
}
.dhx_cal_event_line .dhx_event_resize_end {
  right: 12px;
}
.dhx_timeline_label_row .item > div::after {
  content: '';
  position: absolute;
  height: calc(100% - 2px);
  width: 5px;
  background-color: #a8b8d5;
  right: 0;
  top: 0px;
}
.dhx_timeline_label_row.dhx_row_folder .dhx_scell_level0::after {
  content: '';
  position: absolute;
  height: 33px;
  width: 5px;
  right: 0;
  top: 0px;
  background-color: #37a5da;
}
.dhx_timeline_label_row.dhx_row_folder .dhx_scell_level1::after {
  content: '';
  position: absolute;
  height: 33px;
  width: 5px;
  right: 0;
  top: 0px;
  background-color: #58ccc6;
}

.dhx_cal_navline {
  display: inline-flex;
}
/* .dhx_cal_navline .dhx_cal_prev_button {
  position: relative!important;
  right: auto;
  left: 10px;
  top: 4px!important;
}
.dhx_cal_navline .dhx_cal_next_button {
  position: relative!important;
  right: auto;
  left: 108px;
  top: 4px!important;
} */
.dhx_cal_navline .dhx_cal_today_button {
  position: relative !important;
  left: 50px;
  top: 4px !important;
}
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar:not(.dhx_second_scale_bar) {
  border-left: 1px solid transparent !important;
  border-top: 1px solid #e0e0e0 !important;
}
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.dhx_second_scale_bar:first-child {
  border-left: 1px solid transparent !important;
}
.dhx_cal_container .dhx_cal_header .dhx_scale_bar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dhx_cal_container .dhx_cal_header .dhx_scale_bar:not(.dhx_second_scale_bar) {
  justify-content: left;
  padding-left: 2px;
}
.dhx_matrix_cell.cellMonthWeek1,
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.cellMonthWeek1,
.dhx_cal_container .dhx_cal_header .dhx_scale_bar.cellMonthWeek1,
.dhx_matrix_cell.cellMonthNum1,
.dhx_matrix_cell.cellMonthNum4,
.dhx_matrix_cell.cellMonthNum7,
.dhx_matrix_cell.cellMonthNum10,
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.cellMonthNum1,
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.cellMonthNum4,
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.cellMonthNum7,
.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar.cellMonthNum10 {
  border-left: 1px solid #e0e0e0 !important;
}
.dhx_cal_header {
  border-bottom: 3px solid #00b0f3;
}

.dhx_cal_prev_button,
.dhx_cal_next_button {
  display: none;
}

.my_dhx_cal_prev_button {
  position: absolute;
  cursor: pointer;
  background-color: none;
  background-image: url(/static/media/arrow_left.be80c5ba.svg);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  border-radius: 5px 0 0 5px;
  left: 14px;
  top: 4px;
  z-index: 750;
}
.my_dhx_cal_next_button {
  position: absolute;
  cursor: pointer;
  background-color: none;
  background-image: url(/static/media/arrow_right.83ab4b2f.svg);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  border-radius: 0 5px 5px 0;
  left: 138px;
  top: 4px;
  z-index: 750;
}
.my_dhx_cal_prev_button:hover,
.my_dhx_cal_next_button:hover {
  border: 1px solid transparent;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease-in-out;
}

.dhx_cal_container .hide {
  visibility: hidden;
}

.dhx_timeline_table_wrapper .dhx_cal_event_line.eventType--milestone {
  width: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  border-radius: 0 !important;
  transform: rotate(45deg);
  color: transparent !important;
  margin-top: 4px;
}

.dhx_cal_event_line.disabled:hover .dhx_event_resize {
  display: none;
}
.dhx_timeline_table_wrapper .dhx_cal_event_line.eventType--milestone > .dhx_event_resize {
  display: none;
}
.dhtmlXTooltip.tooltip {
  position: absolute !important;
  z-index: 10000 !important;
}

.dhx_matrix_scell .dhx_scell_level0 {
  padding-left: 0px !important;
}
.dhx_matrix_scell.folder .dhx_scell_expand {
  margin: 0 6px !important;
}
.dhx_matrix_scell.item .dhx_scell_name {
  padding-left: 25px !important;
}
.dhx_matrix_scell.item .dhx_scell_level1 {
  padding-left: 2px !important;
}
.dhx_matrix_scell.item .dhx_scell_level2 {
  padding-left: 22px !important;
}
.dhx_matrix_scell.item .dhx_scell_name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
