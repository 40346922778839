/* css fixes for material skin */
.gantt_cal_light,
.gantt_cal_larea,
.gantt_cal_ltext {
  box-sizing: content-box;
  height: auto !important;
}

.dhtmlx_modal_box {
  z-index: 20000 !important;
}

.gantt_side_content,
.gantt_task_content,
.gantt_task_progress {
  overflow: visible !important;
}

.gantt_task_line.on_plan {
  border: 1px dashed #adaaaa !important;
}

.gantt_task_line.link_red {
  border: 2px solid red !important;
}

.gantt_task_line.link_red.on_plan {
  border: 2px dashed red !important;
}

.block_drag .gantt_task_content {
  cursor: default;
}

.ganttStatus-Gray .gantt_cell:not(:first-child)[aria-label=''] {
  color: #b0bec5;
}

.ganttStatus-Red .gantt_cell:not(:first-child)[aria-label='Red'] {
  color: #ff154b;
}

.ganttStatus-Green .gantt_cell:not(:first-child)[aria-label='Green'] {
  color: #59d146;
}

.ganttStatus-Yellow .gantt_cell:not(:first-child)[aria-label='Yellow'] {
  color: #fde000;
}

.ganttStatus-Blue .gantt_cell:not(:first-child)[aria-label='Blue'] {
  color: #93e3ff;
}

.gantt_task_bg > div {
  background: transparent !important;
}

.gantt_tooltip {
  font-weight: normal;
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 10000;
}

.gantt_tooltip p {
  white-space: normal;
}

.ganttGroupGridRow {
  /* background: #f6feff !important; */
  -webkit-text-fill-color: #666;
  border-top: 1px solid #efefef;
}

.gantt__projectWarning {
  display: contents;
}

.gantt__projectWarning svg {
  width: 20px;
  color: #f3ae3d;
  float: left;
}

.ganttGroup2GridRow .gantt_tree_content {
  color: #58ccc6;
}

.ganttGroupGridRow--timeline .gantt_tree_content {
  font-size: 0.875rem;
  font-weight: 500;
  color: #191919;
  gap: 4px;
}

.ganttGroupGridRow--timeline--bordered {
  border-top: 1px solid #efefef;
}

.ganttGroupGridRow .gantt_add,
.ganttGroup2GridRow .gantt_add,
.ganttGroupGridRow--timeline .gantt_add {
  display: none !important;
}

.gantt_add,
.gantt_grid_head_add {
  background-size: 12px;
  display: none !important;
}

.ganttGroupRow {
  /* background: #f6feff !important;*/
  z-index: 99999 !important;
  border-top: 1px solid #efefef;
  background: transparent !important;
}

.gantt_grid_editor_placeholder {
  position: absolute;
  margin-top: 1px;
}
.gantt_grid_editor_placeholder input,
.gantt_grid_editor_placeholder select {
  width: 100%;
  border-radius: 0px !important;
  padding: 6px 4px;
  box-shadow: none;
  outline: none !important;
  border: 1px solid rgb(0, 157, 255) !important;
  outline-color: rgb(0, 157, 255) !important;
  outline-width: 1px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}
.gantt_grid_editor_placeholder input[type='date'] {
  min-width: 140px;
}
.gantt_grid_editor_placeholder select {
  min-width: 100px;
}

.gantt_task_line.stageBacklog {
  background-color: transparent !important;
  border: 1px dashed !important;
}
.gantt_task_line.stageBacklog .gantt_task_content {
  color: #333 !important;
}
.gantt_task_line.stagePlanning {
  border: 1.5px dashed !important;
}

.ganttGroupTask,
.hideGroupTask {
  opacity: 0 !important;
}

.gantt_scale_cell,
.gantt_task_cell {
  display: inline-block;
  border-right: 1px solid #f8f7f7;
  height: 100%;
}

.gantt_grid .gantt_grid_scale .gantt_grid_head_cell {
  color: #4f5762;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  padding-left: 6px;
  padding-right: 6px;
}

.gantt_layout_cell .gantt_task .gantt_task_scale .gantt_scale_cell {
  font-family: 'Inter';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #4f5762;
  text-transform: initial;
}

.gantt_layout_cell .gantt_task .gantt_task_scale .gantt_scale_line:nth-child(1) .gantt_scale_cell {
  background-color: #fdfdfd;
}

.gantt_layout_cell .gantt_task .gantt_task_scale .gantt_scale_line:nth-child(2) .gantt_scale_cell {
  color: #b1b1b1;
}

.gantt_marker {
  background-color: #934fea !important;
}

.gantt_marker .gantt_marker_content {
  padding: 5px;
  background: inherit;
  color: #fff;
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.5;
  border-radius: 0px 15px 15px 0px;
  text-transform: uppercase;
}

.gantt_task_line {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-weight: 400;
}

.gantt_task_line:not(.gantt_group):not(.gantt_milestone) {
  border-radius: 10px;
}

.gantt_task_line.gantt_group {
  /* background-color: transparent!important; */
  height: 14px !important;
  border-radius: 6px !important;
  box-shadow: none !important;
}
.gantt_task_line.gantt_group .gantt_task_content {
  color: transparent !important;
  /* background-color: #37a5da; */
}

.gantt_task_line.gantt_group .gantt_task_content svg {
  display: none;
}

.gantt_task_line.gantt_group .gantt_task_progress_wrapper {
  height: 12px !important;
  z-index: 1000;
}

.gantt_task_line.gantt_circle_task_format {
  border-radius: 10px;
  height: 20px !important;
  width: 20px !important;
}

.gantt_task_line.gantt_milestone:not(.gantt_task_story) {
  height: 20px !important;
  width: 20px !important;
  margin-left: 5px;
  background-color: #934fea !important;
  border: none !important;
}

.gantt_task_line.gantt_project {
  background-color: #37a5da;
  border: 1px solid rgb(8, 23, 30);
}

.gantt_task_line.gantt_project .gantt_task_progress {
  background-color: rgb(8, 23, 30);
}

.gantt_task_line.gantt_task_inline_color {
  border-color: transparent;
}

.gantt_task_content {
  font-size: 14px;
  font-weight: normal;
  padding: 0 6px;
}

div.gantt_grid_scale::after,
div.gantt_scale_line:last-child::after {
  box-shadow: none;
}

.no-scale .gantt_grid_scale,
.no-scale .gantt_task_scale {
  display: none;
}

.no-scale .gantt_grid_data {
  height: auto !important;
}

.gantt_scale_cell {
  padding-left: 3px;
}

.gantt_grid_head_cell {
  text-align: left;
}

.gantt_cell {
  display: inline-flex;
}

.gantt_cell .gantt_tree_content {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 8px;
}

.gantt_cell .gantt_tree_content .hightlightWrapper {
  font-size: 14px;
}

.gantt_grid_data .gantt_cell:not(:first-child) {
  color: #b1b1b1;
  font-size: 12px;
}

.gantt_task_content {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.gantt_tooltip_hidden {
  display: none;
}

.marker_line_today,
.marker_line_timeframe {
  z-index: 750;
}

.gantt_task_link .gantt_line_wrapper,
.gantt_link_arrow {
  z-index: 100000;
}

.gantt_split_parent {
  opacity: 0.2;
  pointer-events: none;
}
.gantt_task_story .gantt_task_content,
.gantt_split_parent .gantt_task_content {
  visibility: hidden;
}

.overlap {
  height: 12px !important;
}

.overlap .gantt_task_content {
  margin-top: -5px;
  height: 32px !important;
  font-size: 10px;
}

.dhtmlx_message_area {
  z-index: 1000 !important;
  top: 105px !important;
}

/*
*******************************
FORECAST STYLES
*******************************
*/
.gantt_grid_scale .gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.workday_idle {
  text-align: center;
  background: #ffba00;
  color: #6b6b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.workday_ok {
  text-align: center;
  background: #c0fded;
  color: #6b6b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.workday_over {
  text-align: center;
  background: #ffd9e2;
  color: #6b6b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.workday_null {
  display: none;
}

.businessValueCell {
  text-align: center;
  background: #c0fded;
  color: #6b6b6b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: initial !important;
}

.businessValueCell_null {
  display: none;
}

.businessValueGrid_cell .gantt_tree_content {
  padding-left: 10px;
}

.overUnder .gantt_task_row:not(.zeroStaff) .gantt_task_cell {
  background: #ffba00;
}

.gantt_row.notCommittedIncluded > .gantt_cell {
  color: #191919;
}

.resourceGrid_cell > .gantt_row.notCommittedIncluded > .gantt_cell {
  color: inherit;
}

/* prevent adding subtasks: https://docs.dhtmlx.com/gantt/desktop__crud_task.html */
.gantt_row.noPlus:hover .gantt_add {
  display: none !important;
}
.gantt_add,
.gantt__openLightbox {
  display: none !important;
}
.gantt_row:hover .gantt_add,
.gantt_row:hover .gantt__openLightbox {
  display: block !important;
}
.gantt__openLightbox {
  padding-top: 2px;
}
.gantt__openLightbox svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  color: #7cd1c4;
}

.gantt_grid_head_add.noPlus {
  visibility: hidden;
}

.gantt_task .gantt_scale_line:not(:first-child) .gantt_scale_cell {
  text-align: left;
}

.resourceTimeline_cell .gantt_task_cell {
  border-bottom: 1px solid #efefef;
}

.resourceGrid_cell .gantt_cell {
  border-bottom: 1px solid #efefef;
}

.resourceGrid_cell .gantt_row {
  border-right: 1px solid transparent;
}

.resourceGrid_cell .gantt_grid_scale {
  border-right: 1px solid transparent;
}

.resourceGrid_cell,
.resourceTimeline_cell {
  background: #fdfdfd;
}

.resourceGrid_cell .gantt_grid_head_cell.gantt_grid_head_name {
  padding-left: 16px;
}

.resourceGrid_cell .gantt_grid_data .gantt_cell:not(.gantt_last_cell) {
  padding-left: 16px;
}

.resourceGrid_cell .gantt_grid_data .gantt_cell.gantt_last_cell {
  padding-left: 0;
}

.resourceGrid_cell .gantt_grid_data .gantt_cell {
  color: rgba(0, 0, 0, 0.8);
}

.resourceGrid_cell .gantt_grid_data .gantt_cell {
  font-size: 14px;
}

.deadline,
.plannedDeadline,
.plannedStartDate {
  position: absolute;
  border-radius: 2px;
  box-sizing: border-box;
  width: 0;
  border-left: 2px solid #01c8dd;
  border-right: 2px solid #01c8dd;
  height: 28px;
  margin-top: 2px;
  z-index: 1;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.plannedDeadline,
.plannedStartDate {
  border-left: 2px solid rgb(147, 227, 255) !important;
  border-right: 2px solid rgb(147, 227, 255) !important;
  height: 18px !important;
}

.gantt_task_link.link_green .gantt_line_wrapper div {
  background-color: rgb(88, 204, 198);
}

.gantt_task_link.link_red .gantt_line_wrapper div {
  background-color: rgb(198, 40, 40);
}

.gantt_task_link.link_green .gantt_link_arrow_right {
  border-left-color: rgb(88, 204, 198);
}

.gantt_task_link.link_red .gantt_link_arrow_left {
  border-right-color: rgb(198, 40, 40);
}

.gantt_task_link.link_green .gantt_link_arrow_left {
  border-right-color: rgb(88, 204, 198);
}

.gantt_task_link.link_red .gantt_link_arrow_right {
  border-left-color: rgb(198, 40, 40);
}

.gantt_task_line.dependenciesBefore::before {
  position: absolute;
  content: '';
  top: 3px;
  left: -11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 10px 7px 0;
  border-color: transparent #ff0000 transparent transparent;
  cursor: pointer;
}
.gantt_task_line.dependenciesBefore.dep-before-green::before {
  border-color: transparent #59d146 transparent transparent !important;
}
.gantt_task_line.dependenciesBefore.dep-before-green::after {
  border-color: transparent #59d146 transparent transparent !important;
}
.gantt_task_line.dependenciesAfter::after {
  position: absolute;
  content: '';
  top: 3px;
  right: -11px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 10px;
  border-color: transparent transparent transparent #ff0000;
  cursor: pointer;
}
.gantt_task_line.dependenciesAfter.dep-after-green::after {
  border-color: transparent transparent transparent #59d146 !important;
}
.gantt_task_line.dependenciesAfter.dep-after-green::after {
  border-color: transparent transparent transparent #59d146 !important;
}

.gantt_task_row.gantt_selected {
  width: 0 !important;
}
